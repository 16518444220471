<template>
  <div class="login-box fade-in">
    <div class="login-logo">
      <a v-bind:href="website">
        <img v-bind:src="img_banner" style="width: 90%;" />
      </a>
    </div>

    <div class="login-box-body">
      <div>
        <div class="login-box-msg">
          <div class="text-success" v-if="passwordChanged">
            <i class="fa fa-check"></i>
            <span>
              {{ $t("password_reset_success_simple") }}
            </span>
          </div>
          <div :class="classMessage">
            <i
              class="fa fa-exclamation-triangle"
              v-if="hasMessage"
            ></i>
            <span>
              {{ stateMessage }}
            </span>
          </div>
        </div>
        <form
          action="/dashboard"
          accept-charset="UTF-8"
          method="post"
          autocomplete="off"
          v-on:submit.prevent="onSubmit"
        >
          <div class="row" v-if="invite">
            <div class="form-group col-xs-12">
              <label for="pwd1">{{ $t("first_name") }}</label>
              <input
                name="first_name"
                type="text"
                data-testid="first_name"
                class="form-control"
                required
                :placeholder="$t('first_name')"
                :disabled="disabled"
                v-model="first_name"
                ref="first_name"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="row" v-if="invite">
            <div class="form-group col-xs-12">
              <label for="pwd1">{{ $t("last_name") }}</label>
              <input
                name="last_name"
                type="text"
                data-testid="last_name"
                class="form-control"
                :placeholder="$t('last_name')"
                :disabled="disabled"
                required
                v-model="last_name"
                ref="last_name"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <InputPassword
                v-model="pwd1"
                :placeholder="$t('password')"
                :disabled="disabled"
                :use-policies="true"
                ref="usr"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <InputPassword
                v-model="pwd2"
                label="confirm_password"
                :placeholder="$t('password')"
                :disabled="disabled"
                :pattern="pwd1"
              />
            </div>
          </div>
          <div class="row">
            <!-- /.col -->
            <div class="col-xs-4 col-xs-offset-4">
              <button
                type="submit"
                data-testid="submit"
                class="btn btn-primary btn-block"
                v-bind:disabled="!isValid || disabled"
              >
                {{ $t("confirm") }}
              </button>
            </div>
            <!-- /.col -->
          </div>
        </form>
      </div>
      <!-- /.social-auth-links -->
      <div class="row row-top-gap">
        <div class="col-xs-12" v-if="!invite || /error/.test(info.state)">
          <router-link to="/" data-testid="login-link">
            <i class="fa fa-arrow-left"></i>
            {{ $t(/error/.test(info.state) ? "home" : "back") }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="info.state == 'busy'">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
  </div>
</template>

<script>
import greCAPTCHA from "@/plugins/grecaptcha.js";
import AuthService from "@/services/auth";
import InputPassword from "@/components/registration/input-password.vue";

export default {
  name: "FormUserPasswordReset",
  components: { InputPassword },
  props: {
    token: {
      type: String,
      required: true
    },
    user_id: {
      type: String,
      required: true
    },
    invite: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      pwd1: "",
      pwd2: "",
      first_name: "",
      last_name: "",
      grecaptcha: null,
      progress: {
        state: "initializing",
        message: ""
      },
      passwordChanged: false
    };
  },
  computed: {
    classMessage() {
      if (!this.info?.state || /error/.test(this.info?.state)) return 'text-warning';
      return '';
    },
    hasMessage() {
      return Boolean(/error/.test(this.info?.state));
    },
    stateMessage() {
      return this.info?.message || this.$t("login_no_permissions_allowed");
    },
    img_banner: function() {
      return (
        this.$store.getters["dashboard/property"]("company_banner") ||
        "/static/common/images/hitecnologia_logo.png"
      );
    },
    website: function() {
      return (
        this.$store.getters["dashboard/property"]("company_website") ||
        "https://www.hitecnologia.com.br"
      );
    },
    info() {
      if (this.$store.getters["user/loginError"]) {
        return {
          state: "error",
          message: this.$tc(this.$store.getters["user/loginError"])
        };
      }
      return this.progress;
    },
    isNameValid() {
      return (
        !this.invite ||
        (this.first_name && this.last_name && this.first_name != this.last_name)
      );
    },
    isPwdValid() {
      return this.pwd1 && this.pwd2 && this.pwd1 == this.pwd2;
    },
    disabled() {
      return (
        this.progress.state == "error" ||
        this.progress.state == "initializing" ||
        this.progress.state == "busy" ||
        this.progress.state == "fatal_error"
      );
    },
    isValid() {
      return this.isPwdValid && this.isNameValid;
    },
    payload() {
      let payload = {
        user_id: this.user_id,
        token: this.token,
        new_password1: btoa(this.pwd1),
        new_password2: btoa(this.pwd2)
      };
      if (this.invite) {
        payload.first_name = this.first_name;
        payload.last_name = this.last_name;
      }
      return payload;
    },
    g_site_token() {
      return (
        this.$store.getters["dashboard/property"]("grecaptcha_site_token") || ""
      );
    },
    loggedUser: function() {
      return this.$store.getters["user/loggedUser"];
    },
    isLoading: function() {
      return this.$store.getters["user/isLoading"];
    }
  },
  watch: {
    isLoading: function(nVal, oVal) {
      if (!nVal && oVal) {
        if (this.loggedUser && this.invite) {
          this.$router.push("/dashboard/search");
        }
      }
    }
  },
  methods: {
    onError(details) {
      let self = this;
      self.$swal({
        title: self.$t("an_error_has_occurred"),
        text: details,
        icon: "error"
      });
    },
    onSuccess(callback) {
      let self = this;
      let _cb = callback || function() {};
      self
        .$swal({
          title: self.$t("done"),
          text: self.$t("password_reset_success"),
          icon: "success"
        })
        .then((result) => {
          _cb();
        });
    },
    save(token) {
      // Caso haja erro de Recaptcha
      if (!token) {
        this.$swal({
          title: this.$t("grecaptcha_message_title"),
          text: this.$t("grecaptcha_message_text"),
          icon: "error"
        }).then(() => {
          document.location.reload();
        });
        return;
      }
      this.setProgress("busy");
      var payload = this.payload;
      payload.grecaptcha = token;
      this.srv.request_password_reset(payload).then((response) => {
        if (response) {
          if (typeof response == "object") {
            if (response?.email) {
              this.passwordChanged = true;

              this.$store
                .dispatch("user/login", {
                  email: response.email,
                  password: payload.new_password1
                })
                .then(() => {
                  this.setProgress("success", "");
                  this.$router.push("/");
                })
                .catch((err) => {
                  this.setProgress("error", err);
                });
              return;
            } else {
              this.onSuccess(() => {
                this.setProgress("success", "");
                this.$router.push("/");
              });
            }
          } else {
            this.setProgress("error", response);
            this.onError(response);
          }
        } else {
          this.setProgress("error", "sorry_we_could_not_perform_your_request");
          this.onError(this.$t("sorry_we_could_not_perform_your_request"));
        }
      });
    },
    onSubmit(e) {
      var self = this;
      if (self.isValid) {
        if (self.grecaptcha) {
          self.grecaptcha
            .execute(self.g_site_token, { action: "register" })
            .then(function(token) {
              self.save(token);
            });
        } else {
          self.save();
        }
      }
    },
    setProgress(state, msg) {
      this.$set(this, "progress", {
        state: state,
        message: this.$t(!msg && state == "busy" ? "please_wait_no_timeout" : msg)
      });
    },
    setup() {
      this.setProgress("busy");
      let _onSuccess = (resp) => {
        let $inp = null;
        if (this.invite) {
          this.setProgress(
            "idle",
            "please_fill_out_this_form_to_activate_your_account"
          );
          $inp = this.$refs.first_name;
        } else {
          this.setProgress(
            "idle",
            "please_fill_this_form_to_reset_your_password"
          );
          $inp = this.$refs.usr.$refs.input;
        }
        if ($inp) {
          // nexttick is important here since control was disabled (busy state)
          this.$nextTick(() => {
            $inp.focus();
          });
        }
      };
      let _onError = (err) => {
        if (err && err.status) {
          if (err.status == 404) {
            this.setProgress("fatal_error", "token_not_found");
          } else {
            this.setProgress("fatal_error", err.detail || "invalid_token");
          }
        } else {
          this.setProgress("fatal_error", "invalid_token");
        }
      };
      this.srv
        .validateToken(this.user_id, this.token)
        .then(_onSuccess, _onError);
    }
  },
  beforeCreate() {
    this.srv = new AuthService();
  },
  created() {
    this.$store.dispatch("reset");
    let self = this;
    self.grecaptcha = null;
    if (self.g_site_token) {
      (async function() {
        self.grecaptcha = await greCAPTCHA(self.g_site_token);
      })();
    }
  },
  mounted() {
    this.setup();
  }
};
</script>

<style scoped>
.login-logo {
  min-height: 70px;
}

.login-box {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 450px;
  margin: 3% auto;
}

.login-box-body,
.register-box-body {
  border: 1px solid #dcdada;
  padding: 20px;
  border-radius: 4px;
}

.form-check span {
  vertical-align: top;
}

.login-links {
  line-height: 2em;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.login-box .overlay > .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}

.login-box .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}

.row-top-gap {
  margin-top: 20px;
}

.login-box-msg > div {
  width: 100%;
  height: 100%;
  padding: 10px 20px 0 10px;
}
</style>
